import React from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import Logo from "../image/tattQr.png";

const Header = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      navigate("./");
    } catch (error) {
      console.log("error is", error);
    }
  };
  return (
    <div className="navbar">
      <div className="logoDiv">
        <img src={Logo} className="logo" alt="Logo" />
      </div>
      {token ? (
        <button onClick={handleLogout}>
          <i className="fa fa-fw fa-user desktop-icon"></i>
          <i className="fa fa-sign-out mobile-icon" aria-hidden="true"></i>
          <span className="button-text">Logout</span>
        </button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default Header;

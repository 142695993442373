import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Table, Layout, Spin, Checkbox, Modal, Space, Button } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";
import { BaseUrl } from "../shared/BaseUrl";
import QRCode from "qrcode.react";
import { toast } from "react-toastify";
import {
  DeleteOutlined,
  DeleteTwoTone,
  RemoveRedEyeTwoTone,
} from "@mui/icons-material";

const { Content } = Layout;

export const Dashboard = () => {
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [qrId, setQrId] = useState();
  const [contents, setContents] = useState([]); // State to store fetched content
  const [loading, setLoading] = useState(true); // State to manage loading spinner
  const [currentContentId, setCurrentContentId] = useState(null); // State to store current content id
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal
  const [contentToDelete, setContentToDelete] = useState(null); // State to store the content ID to be deleted
  const token = localStorage.getItem("token");

  const qrCodeRef = useRef();

  useLayoutEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setQRCodeURL(response.data.text);
        setQrId(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  useEffect(() => {
    if (qrId) {
      const fetchContents = async () => {
        try {
          const response = await axios.get(
            `${BaseUrl}/api/content/contents/${qrId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setContents(response?.data?.contents); // Store the fetched content in state
          setCurrentContentId(response?.data?.currentContent?._id); // Store current content id
          setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
          console.error("Fetch content error:", error.message);
          setLoading(false); // Set loading to false in case of error
        }
      };
      fetchContents();
    }
  }, [qrId, token]);

  // Filter contents by type
  const audioContents = contents.filter((item) => item.contentType === "audio");
  const videoContents = contents.filter((item) => item.contentType === "video");
  const imageContents = contents.filter((item) => item.contentType === "image");
  const textContents = contents.filter((item) => item.contentType === "text");

  const handleCheckboxChange = async (checked, record) => {
    console.log({ record });
    if (checked) {
      setIsModalOpen(true);
      setCurrentContentId(record._id);
    } else {
      setCurrentContentId(null); // Uncheck if already checked
    }
  };

  const handleOk = async () => {
    const response = await axios.post(
      `${BaseUrl}/api/content/set/${currentContentId}`,
      { qrId: qrId },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    toast.success(response.data.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async (contentId) => {
    try {
      const response = await axios.delete(
        `${BaseUrl}/api/content/delete/${contentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setContents(contents.filter((item) => item._id !== contentId));
    } catch (error) {
      console.error("Delete content error:", error.message);
    }
  };

  // New function to handle the delete confirmation modal OK button
  const handleDeleteOk = () => {
    if (contentToDelete) {
      handleDelete(contentToDelete);
      setIsDeleteModalOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  // Define columns for each table
  const columns = [
    {
      title: "ID",
      key: "index",
      render: (text, record, index) => index + 1, // Render a simple counting number
    },
    {
      title: "Text",
      dataIndex: "text",
      key: "text",
      render: (text) => (text ? text : <b>N/A</b>), // Render "NA" for null or empty values
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      render: (label) => (label ? <b> {label}</b> : <b>N/A</b>), // Render "NA" for null or empty values
    },
    {
      title: "Upload Time",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "View",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <RemoveRedEyeTwoTone
            onClick={() => {
              window.open(record.contentUrl, "_blank");
            }}
            color="success"
          />
          {/* <Button type="primary">View</Button> */}
        </Space>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => (
        <Space size="middle">
          <DeleteTwoTone
            color="warning"
            danger
            onClick={() => {
              setIsDeleteModalOpen(true); // Open the delete confirmation modal
              setContentToDelete(record._id); // Store the content ID to be deleted
            }}
          />
        </Space>
      ),
    },
    {
      title: "Select",
      key: "checkbox",
      render: (text, record) => (
        <Checkbox
          checked={record._id === currentContentId}
          onChange={(e) => handleCheckboxChange(e.target.checked, record)}
        >
          {/* {record._id === currentContentId && (
            <span style={{ marginLeft: 5, fontWeight: "bold" }}>
              Current Content
            </span>
          )} */}
        </Checkbox>
      ), // Render a checkbox and check it if the id matches current content id
    },
  ];

  const handleDownloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "QRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Layout style={{ padding: "0 24px 24px", background: "transparent" }}>
      {loading ? (
        <Spin
          tip="Loading..."
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div
          style={{
            marginTop: "2rem",
            overflow: "scroll",
            // border: "1px solid #d9d9d9",
            backgroundColor: "rgb(243, 181, 98)",
            borderRadius: 4,
            padding: 24,
            // margin: "0 0 24px 0",
          }}
        >
          <div ref={qrCodeRef}>
            <QRCode className="qr-code" value={qrCodeURL} />
          </div>
          <button
            // className="qr-code-generator-button"
            type="primary"
            onClick={handleDownloadQRCode}
            style={{
              marginTop: "1rem",
              backgroundColor: "rgb(12, 12, 12)",
              width: "30%",

              // border: "2px solid rgb(117, 117, 117)",
            }}
          >
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
        </div>
      )}

      <Content style={{ margin: "24px 0" }}>
        {loading ? (
          <Spin
            tip="Loading..."
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          />
        ) : (
          <>
            <div>
              <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
                <h2>Audio</h2>
                <Table
                  columns={columns}
                  dataSource={audioContents}
                  pagination={false}
                  rowKey="_id"
                  style={{ backgroundColor: "#fff", overflow: "scroll" }}
                  locale={{ emptyText: "No Audio Uploaded" }}
                />
              </div>

              <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
                <h2>Video</h2>
                <Table
                  columns={columns}
                  dataSource={videoContents}
                  pagination={false}
                  rowKey="_id"
                  style={{ backgroundColor: "#fff", overflow: "scroll" }}
                  locale={{ emptyText: "No Video Uploaded" }}
                />
              </div>
              <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
                <h2>Images</h2>
                <Table
                  columns={columns}
                  dataSource={imageContents}
                  pagination={false}
                  rowKey="_id"
                  style={{ backgroundColor: "#fff", overflow: "scroll" }}
                  locale={{ emptyText: "No Image Uploaded" }}
                />
              </div>
              <div style={{ backgroundColor: "rgb(243, 181, 98)" }}>
                <h2>Text</h2>
                <Table
                  columns={columns}
                  dataSource={textContents}
                  pagination={false}
                  rowKey="_id"
                  style={{ backgroundColor: "#fff", overflow: "scroll" }}
                  locale={{ emptyText: "No Text Uploaded" }}
                />
              </div>
            </div>
          </>
        )}
      </Content>
      <Modal
        title="Change content"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          style: {
            backgroundColor: "rgb(243, 181, 98)",
            marginTop: "1rem",
            marginLeft: "-1rem",
          },
        }}
      >
        <p>Are you sure you want change the current content</p>
      </Modal>
      <Modal
        title="Confirm Delete"
        open={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okButtonProps={{
          style: {
            backgroundColor: "rgb(243, 181, 98)",
            marginTop: "1rem",
            marginLeft: "-1rem",
          },
        }}
      >
        <p>Are you sure you want to delete this content?</p>
      </Modal>
    </Layout>
  );
};

import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <header className="footer">
      <h4 className="footer-title">All Rights Reserved By Damon Bottoms</h4>
    </header>
  );
};

export default Footer;

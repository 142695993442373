// import React, { useEffect, useState } from "react";
// import QRCode from "qrcode.react";
// import axios from "axios";
// import "./QRCodeGenerator.css";
// import { useSelector } from "react-redux";
// import { BaseUrl } from "../shared/BaseUrl";
// import { toast } from "react-toastify";
// import "./Content.css";
// const QRCodeGenerator = () => {
//   const [text, setText] = useState("");
//   const [label, setLabel] = useState("");
//   const [file, setFile] = useState(null);
//   const [generatedQR, setGeneratedQR] = useState("");
//   const [qrCodeURL, setQRCodeURL] = useState("");

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchQr = async () => {
//       try {
//         const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         console.log({ response });
//         setQRCodeURL(response.data._id);
//       } catch (error) {
//         console.error("Login error:", error.message);
//       }
//     };
//     fetchQr();
//   }, []);

//   const handleGenerate = async () => {
//     try {
//       const formData = new FormData();
//       formData.append("file", file); // Append file to FormData

//       // JSON object with text and qrCodeURL
//       const data = {
//         text: text,
//         qrCodeId: qrCodeURL, // Ensure the key matches what the backend expects (qrCodeId vs qrCodeURL)
//         label: label,
//       };

//       const response = await axios.post(
//         `${BaseUrl}/api/content/content`,
//         formData, // Send FormData as request body
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "multipart/form-data", // Set Content-Type for FormData
//           },
//           params: data, // Send text and qrCodeId as URL params or query params (optional)
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-right",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//       });
//       console.log({ response });
//     } catch (error) {
//       console.error("Error generating QR code", error);
//     }
//   };
//   // className="qr-code-uploader-input"
//   return (
//     <div className="qr-code-generator-container">
//       <div className="generator-containers">
//         <h2 style={{ color: "rgb(243, 181, 98)" }}>Upload content</h2>
//         <br />
//         <input
//           style={{
//             // borderRadius: "5px",
//             borderRadius: "2rem",
//             borderBottom: "2px solid rgb(243, 181, 98)",
//             borderTop: "2px solid rgb(243, 181, 98)",
//           }}
//           className="qr-code-generator-input"
//           type="text"
//           placeholder="Enter text"
//           value={text}
//           onChange={(e) => setText(e.target.value)}
//           disabled={file !== null} // Disable text input if a file is selected
//         />
//         <h1 style={{ color: "rgb(243, 181, 98)" }}>OR</h1>
//         <input
//           style={{
//             // borderRadius: "5px",
//             borderRadius: "2rem",
//             borderBottom: "2px solid rgb(243, 181, 98)",
//             borderTop: "2px solid rgb(243, 181, 98)",
//           }}
//           className="qr-code-generator-input"
//           type="text"
//           placeholder="Enter Label"
//           value={label}
//           onChange={(e) => setLabel(e.target.value)}
//           // Disable text input if a file is selected
//         />
//         {/* <input
//           type="file"
//           onChange={(e) => setFile(e.target.files[0])}
//           disabled={text !== ""} // Disable file input if text is entered
//           style={{

//           }}
//         /> */}
//         <label
//           htmlFor="file-upload"
//           className="custom-file-upload-content"
//           disabled={text !== ""}
//         >
//           Choose File
//           <input
//             id="file-upload"
//             type="file"
//             onChange={(e) => setFile(e.target.files[0])}
//             disabled={text !== ""}
//             style={{ display: "none" }}
//           />
//         </label>
//         {file && <span className="file-name">{file.name}</span>}
//         <button className="qr-code-generator-button" onClick={handleGenerate}>
//           Upload
//         </button>
//       </div>
//     </div>
//   );
// };
// export default QRCodeGenerator;
import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import "./QRCodeGenerator.css";
import { BaseUrl } from "../shared/BaseUrl";
import { toast } from "react-toastify";
import "./Content.css";
import { Spin } from "antd";

const QRCodeGenerator = () => {
  const [text, setText] = useState("");
  const [label, setLabel] = useState("");
  const [file, setFile] = useState(null);
  const [qrCodeURL, setQRCodeURL] = useState("");
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchQr = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/api/qrcode/user/qr`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log({ response });
        setQRCodeURL(response.data._id);
      } catch (error) {
        console.error("Login error:", error.message);
      }
    };
    fetchQr();
  }, [token]);

  const handleGenerate = async () => {
    setLoading(true); // Show spinner
    try {
      const formData = new FormData();
      formData.append("file", file); // Append file to FormData

      // JSON object with text and qrCodeURL
      const data = {
        text: text,
        qrCodeId: qrCodeURL, // Ensure the key matches what the backend expects (qrCodeId vs qrCodeURL)
        label: label,
      };

      const response = await axios.post(
        `${BaseUrl}/api/content/content`,
        formData, // Send FormData as request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data", // Set Content-Type for FormData
          },
          params: data, // Send text and qrCodeId as URL params or query params (optional)
        }
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log({ response });
    } catch (error) {
      console.error("Error generating QR code", error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  return (
    <div className="qr-code-generator-container">
      <div className="generator-containers">
        <h2 style={{ color: "rgb(243, 181, 98)" }}>Upload content</h2>
        <br />
        <input
          style={{
            borderRadius: "2rem",
            borderBottom: "2px solid rgb(243, 181, 98)",
            borderTop: "2px solid rgb(243, 181, 98)",
          }}
          className="qr-code-generator-input"
          type="text"
          placeholder="Enter text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          disabled={file !== null} // Disable text input if a file is selected
        />
        <h1 style={{ color: "rgb(243, 181, 98)" }}>OR</h1>
        <input
          style={{
            borderRadius: "2rem",
            borderBottom: "2px solid rgb(243, 181, 98)",
            borderTop: "2px solid rgb(243, 181, 98)",
          }}
          className="qr-code-generator-input"
          type="text"
          placeholder="Enter Label"
          value={label}
          onChange={(e) => setLabel(e.target.value)}
          // Disable text input if a file is selected
        />
        <label
          htmlFor="file-upload"
          className="custom-file-upload-content"
          disabled={text !== ""}
        >
          Choose File
          <input
            id="file-upload"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
            disabled={text !== ""}
            style={{ display: "none" }}
          />
        </label>
        {file && <span className="file-name">{file.name}</span>}
        <button className="qr-code-generator-button" onClick={handleGenerate}>
          {loading ? (
            <Spin
              tip="Uploading..."
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            />
          ) : (
            "Upload"
          )}
        </button>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
